import { createSelector } from 'reselect'
import * as types from './SystemNotificationsTypes'

const initialState = {
  list: [],
}

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UNSHIFT_NOTIFICATION: {
      const list = [...state.list]
      list.unshift({
        id: action.payload.id,
        message: action.payload.message,
        type: action.payload.notifiactionType,
      })
      return { ...state, list }
    }
    case types.POP_NOTIFICATION: {
      const list = [...state.list]
      const index = list.findIndex(notification => notification.id === action.payload.id)
      if (index !== -1) {
        list.splice(index, 1)
      }
      return { ...state, list }
    }
    default: {
      return state
    }
  }
}

export default usersReducer

const systemNotifications = state => state.systemNotifications.list

export const getNotifications = createSelector(
  systemNotifications,
  notifications => notifications || [],
)
