import * as actionTypes from './SystemNotificationsTypes'
import * as types from '../../models/SystemNotification'

// eslint-disable-next-line
export const addErrorNotification = message => (
  { type: actionTypes.NEW_NOTIFICATION, message, notifiactionType: types.ERROR }
);
export const addSuccessNotification = message => ({ type: actionTypes.NEW_NOTIFICATION, message, notifiactionType: types.SUCCESS })
export const addInfoNotification = message => ({ type: actionTypes.NEW_NOTIFICATION, message, notifiactionType: types.INFO })

export const removeNotification = id => ({ type: actionTypes.REMOVE_NOTIFICATION, id })
