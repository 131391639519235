import React from 'react'
import { useSelector } from 'react-redux'

import { getCurrentUser } from '../store/CurrentUser/CurrentUserReducer'

const useCurrentUser = () => {
  return useSelector(getCurrentUser)
}

export default useCurrentUser
