import * as types from './CurrentUserTypes'

const initialState = {
  data: null,
  hasFetched: false,
  isFetching: false,
}

const currentUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ME_PENDING: {
      return { ...state, isFetching: true }
    }
    case types.FETCH_ME_FULFILLED: {
      return { ...state, data: action.payload.user, hasFetched: true, isFetching: false }
    }
    case types.FETCH_ME_REJECTED: {
      return { ...state, data: null, hasFetched: true, isFetching: false }
    }
    default: {
      return state
    }
  }
}

export default currentUserReducer

export const getCurrentUser = state =>
  state.currentUser && state.currentUser.data ? state.currentUser.data : {}
